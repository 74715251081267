import { Link } from 'gatsby-plugin-intl';
import * as React from 'react';

const DefaultFooterNavLink: React.FC<{ to: string; text: string }> = ({ to, text }) => {
  return (
    <Link to={to} className="text-base font-medium hover:underline cursor-pointer block">
      {text}
    </Link>
  );
};

export default DefaultFooterNavLink;
