import { Link } from 'gatsby-plugin-intl';
import * as React from 'react';

import DefaultFooterNavLink from './DefaultFooterNavLink';
import useTranslate from '../../hooks/useTranslate';
import HomeHeaderBookButton from '../header/HomeHeaderBookButton';
import { HellyDark } from '../svgs/Helly';
import { WhatsApp } from '../svgs/icons';

const HomeHeader: React.FC = () => {
  const tMenu = useTranslate('menu');
  const tFooter = useTranslate('footer');

  return (
    <div className="bg-gray-50">
      <footer className="md:flex mx-auto max-w-7xl py-24 px-12 justify-between items-start">
        <Link to="/" aria-label="Homepage">
          <HellyDark height={50} />
        </Link>

        <div className="mt-16 md:mt-0">
          <div className="text-3xl font-semibold">{tMenu('title')}</div>

          <nav className="space-y-2 mt-6">
            <DefaultFooterNavLink to="/apartments" text={tMenu('apartments')} />

            <DefaultFooterNavLink to="/gallery" text={tMenu('gallery')} />

            <DefaultFooterNavLink to="/prices" text={tMenu('prices')} />

            <DefaultFooterNavLink to="/activities" text={tMenu('activities')} />

            <DefaultFooterNavLink to="/contact" text={tMenu('contact')} />
          </nav>
        </div>

        <div className="mt-16 md:mt-0">
          <div className="text-3xl font-semibold">{tFooter('contact.title')}</div>

          <div className="mt-6 font-medium">
            <div>
              <a
                href="mailto:info@apartmanyhelly.cz"
                className="font-bold hover:underline cursor-pointer text-secondary-dark"
              >
                info@apartmanyhelly.cz
              </a>
            </div>

            <a
              href="https://wa.me/420734767893"
              className="mt-2 text-base font-bold hover:underline cursor-pointer text-green-600 flex space-x-2 items-center"
            >
              <WhatsApp className="h-8 w-8 block hover:animate-pulse" />

              <span>WhatsApp</span>
            </a>

            <div className="mt-4">
              Kvilda 176
              <br />
              384 93 Kvilda
            </div>

            <div className="mt-8">
              <div className="text-xl font-semibold">{tFooter('billing.title')}</div>

              <div className="mt-6">
                Helly s.r.o.
                <br />
                Bělehradská 314/18, Nusle, 140 00 Praha
                <br />
                IČ: 19600569
              </div>
            </div>
          </div>
        </div>

        <div className="mt-16 md:mt-0">
          <HomeHeaderBookButton />
        </div>
      </footer>
    </div>
  );
};

export default HomeHeader;
