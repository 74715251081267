import "./src/styles/global.css"
import "./src/styles/datepicker.css"
import "./src/styles/carousel.css"
import './src/styles/lightbox.css'
import "animate.css/animate.min.css";
import { ParallaxProvider } from "react-scroll-parallax";
import React from "react"

export const wrapRootElement = ({ element }) => (
    <ParallaxProvider>{element}</ParallaxProvider>
  )