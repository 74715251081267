import { Link } from 'gatsby-plugin-intl';
import * as React from 'react';

import useTranslate from '../../hooks/useTranslate';

const HomeHeaderBookButton: React.FC = () => {
  const tHeader = useTranslate('header');

  return (
    <Link to="/booking">
      <div className="group relative flex items-center text-secondary bg-secondaryComplement px-8 py-3 hover:animate-none hover:scale-105 transition-all duration-500 justify-center">
        <span className="text-base font-semibold uppercase ">{tHeader('book')}</span>

        <span className="absolute h-0.5 w-2 bg-secondary top-1/2 -translate-y-1/2 left-4 group-hover:w-4 group-hover:left-2 transition-all duration-500" />

        <span className="absolute h-0.5 w-2 bg-secondary top-1/2 -translate-y-1/2 right-4 group-hover:w-4 group-hover:right-2 transition-all duration-500" />
      </div>
    </Link>
  );
};

export default HomeHeaderBookButton;
