import { Dropdown } from 'flowbite-react';
import { Link, changeLocale, useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { useEffect, useState } from 'react';

import HomeHeaderBookButton from './HomeHeaderBookButton';
import HomeHeaderNavLink from './HomeHeaderNavLink';
import useTranslate from '../../hooks/useTranslate';
import { Helly } from '../svgs/Helly';
import { CzechFlag, EnglishFlag, GermanFlag } from '../svgs/flags';

const DROPDOWN_THEME_LANGUAGES = {
  content: 'bg-gray-700 text-white focus:outline-none shadow py-3',
  floating: {
    base: 'z-10 w-fit focus:outline-none pr-12',
    style: {
      dark: '',
      light: '',
      auto: '',
    },
    item: {
      base: 'flex items-center justify-start py-2 px-4 text-sm cursor-pointer w-full text-white',
    },
  },
};

const DROPDOWN_THEME_LANGUAGES_MOBILE = {
  content: 'bg-gray-700 text-white focus:outline-none shadow py-3',
  floating: {
    base: 'z-10 w-fit focus:outline-none pr-6',
    style: {
      dark: '',
      light: '',
      auto: '',
    },
    item: {
      base: 'flex items-center justify-start py-2 px-4 text-sm cursor-pointer w-full text-white',
    },
  },
};

const DROPDOWN_THEME = {
  content: 'w-full bg-gray-700 text-white focus:outline-none shadow py-3',
  floating: {
    base: 'z-10 w-full focus:outline-none pr-[16px]',
    style: {
      dark: '',
      light: '',
      auto: '',
    },
    item: {
      base: 'flex items-center justify-start py-2 px-4 text-sm cursor-pointer w-full text-white',
    },
  },
};

const HomeHeader: React.FC = () => {
  const { locale } = useIntl();
  const tMenu = useTranslate('menu');
  const tHeader = useTranslate('header');

  // const [showMenu, setShowMenu] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);

  const handleScroll = () => {
    const position = window.scrollY;

    setScrollPosition(position);
  };

  useEffect(() => {
    handleScroll();

    window.addEventListener('scroll', handleScroll, { passive: true });

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div
      className={`fixed left-0 right-0 h-114 z-50 transition-colors duration-500 ${scrollPosition > 0 ? 'bg-primary' : ''}`}
    >
      <header className="hidden h-114 md:flex mx-auto max-w-7xl px-12 justify-between items-center">
        <Link to="/" aria-label="Homepage">
          <Helly height={50} />
        </Link>

        <nav className="flex space-x-8">
          <HomeHeaderNavLink to="/apartments" text={tMenu('apartments')} />

          <HomeHeaderNavLink to="/gallery" text={tMenu('gallery')} />

          <HomeHeaderNavLink to="/prices" text={tMenu('prices')} />

          <HomeHeaderNavLink to="/activities" text={tMenu('activities')} />

          <HomeHeaderNavLink to="/contact" text={tMenu('contact')} />
        </nav>

        <div className="flex items-center">
          <HomeHeaderBookButton />

          {locale === 'en' && (
            <button className="p-2.5" aria-label="Language" onClick={() => changeLocale('cs')}>
              <CzechFlag className="h-6 w-6" />
            </button>
          )}
          {locale === 'cs' && (
            <button className="p-2.5" aria-label="Language" onClick={() => changeLocale('en')}>
              <EnglishFlag className="h-6 w-6" />
            </button>
          )}

          {/*
          <Dropdown
            label=""
            dismissOnClick
            theme={DROPDOWN_THEME_LANGUAGES}
            renderTrigger={() => (
              <button className="p-2.5" aria-label="Language">
                {locale === 'cs' && <CzechFlag className="h-6 w-6" />}
                {locale === 'en' && <EnglishFlag className="h-6 w-6" />}
                {locale === 'de' && <GermanFlag className="h-6 w-6" />}
              </button>
            )}
          >
            <Dropdown.Item onClick={() => changeLocale('en')}>
              <EnglishFlag className="h-6 w-6 mr-2" /> {tHeader('languages.english')}
            </Dropdown.Item>

            <Dropdown.Item onClick={() => changeLocale('cs')}>
              <CzechFlag className="h-6 w-6 mr-2" /> {tHeader('languages.czech')}
            </Dropdown.Item>

            <Dropdown.Item onClick={() => changeLocale('de')}>
              <GermanFlag className="h-6 w-6 mr-2" /> {tHeader('languages.german')}
            </Dropdown.Item>
          </Dropdown>
          */}
        </div>
      </header>

      <header className="md:hidden h-114 px-6 flex justify-between items-center">
        <Link to="/" aria-label="Homepage">
          <Helly height={50} />
        </Link>

        <div className="flex items-center">
          <Dropdown
            label=""
            dismissOnClick
            theme={DROPDOWN_THEME}
            renderTrigger={() => (
              <button type="button" className="p-2.5" aria-label="Menu">
                <svg
                  className="h-8 w-8 stroke-white"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  aria-hidden="true"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
                </svg>
              </button>
            )}
          >
            {['apartments', 'gallery', 'prices', 'activities', 'contact'].map((link) => (
              <Dropdown.Item key={link} as={Link} to={`/${link}`} className="text-base py-3 justify-center">
                {tMenu(link)}
              </Dropdown.Item>
            ))}

            <Dropdown.Item className="justify-center">
              <HomeHeaderBookButton />
            </Dropdown.Item>
          </Dropdown>

          {locale === 'en' && (
            <button className="p-2.5" aria-label="Language" onClick={() => changeLocale('cs')}>
              <CzechFlag className="h-6 w-6" />
            </button>
          )}
          {locale === 'cs' && (
            <button className="p-2.5" aria-label="Language" onClick={() => changeLocale('en')}>
              <EnglishFlag className="h-6 w-6" />
            </button>
          )}

          {/*<Dropdown
            label=""
            size="sm"
            dismissOnClick
            theme={DROPDOWN_THEME_LANGUAGES_MOBILE}
            renderTrigger={() => (
              <button className="p-2.5" aria-label="Language">
                {locale === 'cs' && <CzechFlag className="h-6 w-6" />}
                {locale === 'en' && <EnglishFlag className="h-6 w-6" />}
                {locale === 'de' && <GermanFlag className="h-6 w-6" />}
              </button>
            )}
          >
            <Dropdown.Item onClick={() => changeLocale('en')}>
              <EnglishFlag className="h-6 w-6 mr-2" /> {tHeader('languages.english')}
            </Dropdown.Item>

            <Dropdown.Item onClick={() => changeLocale('cs')}>
              <CzechFlag className="h-6 w-6 mr-2" /> {tHeader('languages.czech')}
            </Dropdown.Item>

            <Dropdown.Item onClick={() => changeLocale('de')}>
              <GermanFlag className="h-6 w-6 mr-2" /> {tHeader('languages.german')}
            </Dropdown.Item>
          </Dropdown>*/}
        </div>
      </header>
    </div>
  );
};

export default HomeHeader;
