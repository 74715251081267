import { Link } from 'gatsby-plugin-intl';
import * as React from 'react';

const HomeHeaderNavLink: React.FC<{ to: string; text: string }> = ({ to, text }) => {
  return (
    <Link
      to={to}
      className="h-57 flex text-sm font-semibold text-white items-center uppercase hover:border-white cursor-pointer border-b-2 border-transparent transition-colors duration-300"
      activeClassName="border-white"
    >
      {text}
    </Link>
  );
};

export default HomeHeaderNavLink;
