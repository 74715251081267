import { getMonth } from 'date-fns';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import * as React from 'react';
import { Helmet } from 'react-helmet';

import DefaultFooter from '../components/footer/DefaultFooter';
import HomeHeader from '../components/header/HomeHeader';
import useLocalStorage from '../hooks/useLocalStorage';
import useTranslate from '../hooks/useTranslate';

const Layout: React.FC<{ children?: React.ReactNode; location: Location }> = ({ children, location }) => {
  const [showCookies, setShowCookies] = useLocalStorage('cookies', true);
  const { locale } = useIntl();
  const tLayout = useTranslate('layout');

  const summer = getMonth(new Date()) > 2 && getMonth(new Date()) < 10;

  return (
    <>
      {showCookies && (
        <div className="fixed bottom-0 left-0 right-0 p-4 flex items-center gap-x-4 justify-between bg-slate-600 z-20">
          <div className="text-white">{tLayout('cookies.text')}</div>

          <button
            type="button"
            className="font-semibold text-secondary bg-secondaryComplement px-6 py-2 cursor-pointer hover:animate-none hover:scale-105 transition-all duration-500"
            onClick={() => {
              setShowCookies(false);
            }}
          >
            {tLayout('cookies.button')}
          </button>
        </div>
      )}

      <Helmet>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Raleway:wght@400..700&display=swap" />
        <link rel="stylesheet" href="https://fonts.googleapis.com/css2?family=Spectral:wght@400&display=swap" />
        <html lang={locale} />

        <script async src="https://www.googletagmanager.com/gtag/js?id=G-Y2H5SRPS9B" />
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());

            gtag('config', 'G-Y2H5SRPS9B');
            `}
        </script>
      </Helmet>

      {/*<Script id="load-gfonts" strategy="post-hydrate">
        {`
          var raleway = document.createElement("link");
          raleway.setAttribute("href", "https://fonts.googleapis.com/css2?family=Raleway:wght@400..700&display=swap");
          raleway.setAttribute("rel", "stylesheet");

          document.head.appendChild(raleway);

          var spectral = document.createElement("link");
          spectral.setAttribute("href", "https://fonts.googleapis.com/css2?family=Spectral:wght@400&display=swap");
          spectral.setAttribute("rel", "stylesheet");

          document.head.appendChild(spectral);
        `}
      </Script>
      */}

      <HomeHeader />

      {summer ? (
        <div className="absolute top-0 left-0 right-0 h-450 md:h-600 w-full -z-10 bg-primary">
          {location.pathname.includes('apartments') && (
            <StaticImage
              src="../images/intro/apartments_summer.jpg"
              alt="Apartment"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('prices') && (
            <StaticImage
              src="../images/intro/prices_summer.jpg"
              alt="Apartment"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('gallery') && (
            <StaticImage
              src="../images/intro/gallery_summer.jpg"
              alt="Apartment"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('activities') && (
            <StaticImage
              src="../images/intro/surroundings_summer.jpg"
              alt="Surroundings"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('contact') && (
            <StaticImage
              src="../images/intro/contact_summer.jpg"
              alt="Surroundings"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('booking') && (
            <StaticImage
              src="../images/intro/booking_summer.jpg"
              alt="Surroundings"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}
        </div>
      ) : (
        <div className="absolute top-0 left-0 right-0 h-450 md:h-600 w-full -z-10 bg-primary">
          {location.pathname.includes('apartments') && (
            <StaticImage
              src="../images/intro/apartments.jpg"
              alt="Apartment"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('prices') && (
            <StaticImage
              src="../images/intro/prices.jpg"
              alt="Apartment"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('gallery') && (
            <StaticImage
              src="../images/intro/gallery.jpg"
              alt="Apartment"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('activities') && (
            <StaticImage
              src="../images/intro/surroundings.jpg"
              alt="Surroundings"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('contact') && (
            <StaticImage
              src="../images/intro/contact.jpg"
              alt="Surroundings"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}

          {location.pathname.includes('booking') && (
            <StaticImage
              src="../images/intro/booking.jpg"
              alt="Surroundings"
              className="object-cover h-full w-full brightness-35 animate-reveal"
            />
          )}
        </div>
      )}

      {children}

      <DefaultFooter />
    </>
  );
};

export default Layout;
