exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-activities-index-tsx": () => import("./../../../src/pages/activities/index.tsx" /* webpackChunkName: "component---src-pages-activities-index-tsx" */),
  "component---src-pages-apartments-index-tsx": () => import("./../../../src/pages/apartments/index.tsx" /* webpackChunkName: "component---src-pages-apartments-index-tsx" */),
  "component---src-pages-booking-index-tsx": () => import("./../../../src/pages/booking/index.tsx" /* webpackChunkName: "component---src-pages-booking-index-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-gallery-index-tsx": () => import("./../../../src/pages/gallery/index.tsx" /* webpackChunkName: "component---src-pages-gallery-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-prices-index-tsx": () => import("./../../../src/pages/prices/index.tsx" /* webpackChunkName: "component---src-pages-prices-index-tsx" */)
}

